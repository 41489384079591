import css from "@emotion/css"
import styled from "@emotion/styled"
import {
  Box,
  Button,
  Column,
  Columns,
  Container,
  Content,
  Section,
  Subtitle,
  Table,
  Title,
} from "bloomer"
import React from "react"

import ImageWrap from "../atoms/image-wrap.atom"
import VixyBlock from "../blocks/vixy-custom.block"
import { YELLOW } from "../consts/colors.constants"
import Layout from "../layouts"
import { ContentSection } from "./content-section.component"
import { FeedbackList } from "./feedbacklist.component"
import { GalleryCarousel } from "./gallery-carousel.component"
import { ModelsCarousel } from "./model-carousel.component"
import { Backgrounded, StyledBackground } from "./styled-background-section.component"

const SiderTitle = styled(Title)`
  opacity: 0.03;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  color: #000000;
  font-size: 180px;
  font-weight: bold;
  text-transform: uppercase;
  -webkit-letter-spacing: 30px;
  -moz-letter-spacing: 30px;
  -ms-letter-spacing: 30px;
  letter-spacing: 34px;
  position: absolute;
  right: -320px;
  top: 430px;
`
export const ModelSingleTemplate = props => {
  const { model, models } = props.data
  const { acf } = model
  const { content_sections, gallery_public } = acf
  return (
    <Layout title={model.title}>
      <div className="model-single-template">
        <Section>
          {model && (
            <Container>
              <div
                css={css`
                  padding-top: 7rem;
                  padding-bottom: 91px;
                `}
              >
                <Columns>
                  <Column isSize={10} isOffset={1}>
                    <Columns>
                      <Column isSize={5}>
                        <Title
                          css={css`
                            margin-top: 68px;
                            height: 89px;
                            font-size: 60px;
                            line-height: 89px;
                          `}
                        >
                          {model.title}
                        </Title>
                        <Subtitle
                          css={css`
                            font-size: 24px;
                            letter-spacing: 0.6px;
                            line-height: 36px;
                          `}
                        >
                          <span dangerouslySetInnerHTML={{ __html: model.excerpt }} />
                        </Subtitle>
                        <div
                          css={css`
                            margin: 3rem 0;
                            border: 1px solid #979797;
                            opacity: 0.3;
                            width: 50%;
                          `}
                        />
                        <Table className="details-table" isBordered={false} isStriped={false}>
                          <tbody>
                            <tr>
                              <td>Age</td>
                              <td>{acf.birthdate}</td>
                            </tr>
                            <tr>
                              <td>Height</td>
                              <td>{acf.height}</td>
                            </tr>
                            <tr>
                              <td>Dress Size</td>
                              <td>{acf.dress_size}</td>
                            </tr>
                            <tr>
                              <td>Lingerie</td>
                              <td>{acf.lingerie}</td>
                            </tr>
                            <tr>
                              <td>Eyes</td>
                              <td>{acf.eyes}</td>
                            </tr>
                            <tr>
                              <td>Occupation</td>
                              <td>{acf.occupation}</td>
                            </tr>
                            <tr>
                              <td>Languages</td>
                              <td>{acf.languages}</td>
                            </tr>
                          </tbody>
                        </Table>
                        <Button
                          css={css`
                            padding-left: 58px;
                            padding-right: 58px;
                          `}
                        >
                          Book now
                        </Button>
                      </Column>
                      <Column isOffset={1} isSize={6}>
                        <ImageWrap responsiveStyle={`fixed`} image={acf.profile_image} />
                      </Column>
                    </Columns>
                  </Column>
                </Columns>
              </div>
            </Container>
          )}

          <SiderTitle>Models</SiderTitle>
        </Section>
        <Section
          css={css`
            background-color: #fafafa;
          `}
          isPaddingless
          className="video-section"
        >
          <Container>
            <Columns>
              <Column isSize={12}>
                {model.acf.model_video_vixy && <VixyBlock vixy_id={model.acf.model_video_vixy} />}
              </Column>
            </Columns>
          </Container>
        </Section>
        <Section isPaddingless>
          <GalleryCarousel slides={gallery_public} responsiveStyle={`fixed`} height={514} />
        </Section>
        <Section
          isPaddingless
          css={css`
            margin-top: 1rem;
          `}
        >
          <Columns isVCentered>
            <Column
              isSize={{ mobile: 12, tablet: 5 }}
              css={css`
                flex: 1;
                width: 41.66667%;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                display: flex;
              `}
            >
              <Box className="box-description-main">
                <Content dangerouslySetInnerHTML={{ __html: model.acf.model_description_main }} />
              </Box>
            </Column>
            <Column isSize={{ mobile: 12, tablet: 7 }}>
              {model.acf.model_description_image && (
                <StyledBackground image_height={768} image={model.acf.model_description_image} />
              )}
            </Column>
          </Columns>
        </Section>
        <Section
          isPaddingless
          css={css`
            margin-top: 1rem;
          `}
        >
          <Columns isGapless isVCentered>
            <Column>
              <Content
                className={`theme-dark`}
                css={css`
                  background-color: #111;
                  height: 50vh;
                  color: white;
                  padding: 4rem;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  strong,
                  h3 {
                    color: ${YELLOW};
                    font-size: 18px;
                  }
                `}
                dangerouslySetInnerHTML={{ __html: model.acf.model_description_left }}
              />
            </Column>
            <Column>
              <Backgrounded
                {...model.acf.model_description_right_background_image}
                responsiveStyle={`fixed`}
                image_height={508}
                css={css`
                  height: 508px;
                `}
              >
                <Content
                  css={css`
                    padding: 4rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    strong,
                    h3 {
                      color: ${YELLOW};
                    }
                  `}
                  dangerouslySetInnerHTML={{ __html: model.acf.model_description_right }}
                />
              </Backgrounded>
            </Column>
          </Columns>
        </Section>

        <Section isPaddingless>
          {content_sections && <ContentSection content_sections={content_sections} />}
        </Section>
        {model.acf.feedback && model.acf.feedback.length > 0 && (
          <Section>
            <FeedbackList feedback={model.acf.feedback} />
          </Section>
        )}
        <Section
          hasTextAlign={`centered`}
          className={`theme-dark`}
          css={css`
            background-color: #111;
            color: white;
          `}
        >
          Feedback Form
        </Section>

        <Section
          isMarginless
          isPaddingless
          css={css`
            padding-bottom: 4rem !important;
          `}
        >
          {models && (
            <ModelsCarousel
              carouselStyle={`category`}
              responsiveStyle={`fluid`}
              css={css`
                margin-bottom: 4rem;
              `}
              models={models.edges
                .filter(({ node }) => node.id !== props.pageContext.id)
                .map(({ node }) => node)}
            />
          )}
        </Section>
      </div>
    </Layout>
  )
}
