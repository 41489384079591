import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { ModelSingleTemplate } from "../components/model-single-template.component"

export const ModelTemplate = props => <ModelSingleTemplate {...props} />

ModelTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ModelTemplate

export const queryModel = graphql`
  fragment ModelProfileFields on wordpress__wp_model {
    ...ModelDefaultFields
    acf {
      ...ModelAcfFields
    }
  }

  fragment ModelCarouselCategoryFields on wordpress__wp_model {
    ...ModelDefaultFields
    acf {
      ...ModelDefaultAcfFields
      gallery_image {
        ...ImageFields
        localFile {
          ...ModelProfileImageMain
        }
      }
    }
  }

  fragment ModelAcfFields on wordpress__wp_modelAcfCustom {
    ...ModelDefaultAcfFields
    content_sections {
      orientation
      text
      style
      section_image {
        ...ImageFields
        localFile {
          ...ResponsiveImageHD
        }
      }
    }
    gallery_public {
      id
      source_url
      localFile {
        ...ModelGalleryImage
      }
    }
    profile_image {
      id
      source_url
      localFile {
        ...ModelProfileImage
      }
    }
    feedback {
      author
      message
    }
    model_video_vixy
    model_description_main
    model_description_left
    model_description_right
    model_description_right_background_image {
      id
      source_url
      localFile {
        ...ModelProfileImageMain
      }
    }
    model_description_image {
      id
      source_url
      localFile {
        ...ModelProfileImageMain
      }
    }
  }

  query ModelById($slug: String!) {
    model: wordpressWpModel(slug: { eq: $slug }) {
      ...ModelProfileFields
    }
    models: allWordpressWpModel {
      edges {
        node {
          ...ModelListFields
          acf {
            gallery_image {
              localFile {
                ...ModelProfileImageMain
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
