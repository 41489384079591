import { Container, Content, Section } from "bloomer"
import * as React from "react"
import VixyVideo from "../components/vixy-video.component"

const VixyBlock = props => (
  <Section isPaddingless className="video-row">
    <Container>
      <Content>
        <VixyVideo {...props} vixy_id={props.vixy_id || props.attrs.vixy_id} />
      </Content>
    </Container>
  </Section>
)

export default VixyBlock
