import React from "react"

export const FeedbackListItem = ({ message, author }) => (
  <div className="feedback-list-item">
    <blockquote
      className="feedback-list-item--quote"
      dangerouslySetInnerHTML={{ __html: message }}
    ></blockquote>
    <div className="feedback-list-item--author">{author}</div>
  </div>
)
