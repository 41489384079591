/* eslint-disable camelcase */
import css from "@emotion/css"
import styled from "@emotion/styled"
import { Column, Columns } from "bloomer"
import { useTheme } from "emotion-theming"
import React from "react"

import { THEMES } from "../config/theme"
import { ORIENTATION } from "../consts/acf.constants"
import { BLACK, DARK } from "../consts/colors.constants"
import BackgroundSection from "./styled-background-section.component"

export const StyledColumn = styled(Column)`
  overflow: hidden;
  padding: 0;
`

export const ContentRow = props => {
  const {
    orientation,
    content_section_image_align,
    content_section_spacing_top,
    content_section_spacing_bottom,
    style,
    text,
    vixy_video,
    section_image,
  } = props
  const theme = useTheme()
  const rowTheme = THEMES[style] || THEMES[theme] || THEMES.default
  return (
    <Columns
      isMarginless
      isGapless
      className={`content-row content-section-row theme-${style}`}
      css={css`
        background-color: ${rowTheme.backgroundColor || orientation === `full` ? BLACK : DARK};
        color: ${rowTheme.color};
        margin-bottom: ${content_section_spacing_bottom || 0}px !important;
        margin-top: ${content_section_spacing_top || 0}px !important;
        h2 {
          color: ${rowTheme.color};
        }
      `}
    >
      <StyledColumn
        key={`left`}
        className={`col-left col-orientation-${orientation}`}
        isSize={{ desktop: orientation === `full` ? 12 : `1/2`, mobile: 12 }}
      >
        <BackgroundSection
          text={orientation !== ORIENTATION.RIGHT && text}
          slide={
            orientation !== ORIENTATION.LEFT && {
              ...section_image,
              content_section_image_align,
            }
          }
          vixy_video={orientation !== ORIENTATION.LEFT && vixy_video}
        />
      </StyledColumn>
      {orientation !== `full` && (
        <StyledColumn key={`right`} className={`col-right`} isSize={6}>
          <BackgroundSection
            text={orientation === ORIENTATION.RIGHT && text}
            slide={orientation === ORIENTATION.LEFT && { ...section_image, type: `fluid` }}
            vixy_video={orientation === ORIENTATION.LEFT && vixy_video}
          />
        </StyledColumn>
      )}
    </Columns>
  )
}
