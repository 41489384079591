import { Icon } from "bloomer"
import React from "react"
import { ModelsCarouselCategory } from "./model-carousel-category.component"
import { ModelsCarouselCentered } from "./model-carousel-centered.component"
import { ModelsCarouselHome } from "./model-carousel-home.component"

export const CarouselArrow = ({ direction }) => (
  <Icon className={`is-arrow-button fas fa-angle-${direction} fa-lg`} />
)
export const ModelsCarousel = props => {
  switch (props.carouselStyle) {
    case `category`:
      return <ModelsCarouselCategory {...props} />
    case `home`:
      return <ModelsCarouselHome {...props} />
    case `centered`:
      return <ModelsCarouselCentered {...props} />
    default:
      return <ModelsCarouselHome {...props} />
  }
}
