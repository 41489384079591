/* eslint-disable camelcase */
import styled from "@emotion/styled"
import { Section } from "bloomer"
import React from "react"
import { themedBox } from "../utils/theme.utils"
import { ContentRow } from "./content-row.component"

export const ContentSection = ({ content_sections }) => (
  <Section isPaddingless className="content-section">
    {content_sections &&
      content_sections.map((section, i) => (
        <ContentRow key={`content-section-row-${i}`} {...section} />
      ))}
  </Section>
)

export const ThemedContentSection = styled(ContentSection)`
  ${themedBox}
`
