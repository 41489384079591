/* eslint-disable camelcase */
import css from "@emotion/css"
import Img from "gatsby-image"
import get from "lodash/get"
import React from "react"

export const ModelCarouselCategoryItem = ({ model, objectFit, placeholder }) => {
  const { acf, slug, title } = model
  const { gallery_image } = acf
  return (
    <div
      className={`embla__slide model-slider--item model-slider--item-${slug}`}
      css={css`
        padding: 0rem;
        max-width: 100%;
        flex: 0 0 100%;
        max-height: 664px;
        position: relative;
        overflow: hidden;
      `}
    >
      <div className="embla__slide__inner">
        {gallery_image ? (
          <Img
            objectFit={objectFit || `top left`}
            fixed={get(gallery_image, `localFile.childImageSharp.fixed`, ``)}
            alt={get(gallery_image, `alt`, title)}
          />
        ) : null}
      </div>
    </div>
  )
}
