import css from "@emotion/css"
import styled from "@emotion/styled"
import { Content, Icon, Title } from "bloomer"
import { Link, graphql } from "gatsby"
import React, { useCallback, useEffect, useState } from "react"

import { DARK, LIGHT, YELLOW } from "../consts/colors.constants"
import { EmblaCarousel } from "./embla.component"
import { ModelCarouselCategoryItem } from "./model-carousel-category-item.component"

export const CarouselArrow = ({ direction }) => (
  <Icon className={`is-arrow-button fas fa-angle-${direction} fa-lg`} />
)

export const StyledInfoBlock = styled.div`
  background-color: white;
  height: 471px;
  margin-left: -50%;
  margin-top: ${Math.round((652 - 471) / 2)}px;
  padding: 125px;
  position: relative;
  text-align: left;
  width: 652px;
  .title.is-3 {
    font-size: 60px;
    height: 50px;
    line-height: 50px;
  }
  p {
    margin-top: 1rem;
    margin-left: 2rem;
  }
  .controls {
    left: 0;
    position: absolute;
    top: 50%;
    margin-top: -70px;
    width: 70px;
    .icon {
      width: 70px;
      height: 70px;
      background-color: ${DARK};
      color: ${LIGHT};
      border: 1px solid ${DARK};
      &:hover {
        // background: transparent;
        color: ${YELLOW};
        cursor: pointer;
      }
    }
  }
`

export const StyledInfoHeader = styled.header`
  text-transform: uppercase;
  padding-left: 2rem;
  color: #000000;
  font-size: 12px;
  letter-spacing: 7.5px;
  line-height: 50px;
  position: relative;
  &::before {
    content: " ";
    border-top: 1px solid #000;
    width: 4rem;
    height: 1px;
    position: absolute;
    left: 0;
    top: 0;
  }
`

export const ModelsCarouselCategory = ({ models, placeholderImage }) => {
  const [embla, setEmbla] = useState(null)
  const [emblaPrev, setEmblaPrev] = useState(null)
  // const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  // const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  // const [scrollSnaps, setScrollSnaps] = useState([])

  const scrollTo = useCallback(index => embla.scrollTo(index), [embla])
  const scrollPrev = useCallback(() => embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla.scrollNext(), [embla])
  const [model, setModel] = useState(models ? models[0] : null)
  useEffect(() => {
    const onSelect = () => {
      setSelectedIndex(embla.selectedScrollSnap())
      // setPrevBtnEnabled(embla.canScrollPrev())
      // setNextBtnEnabled(embla.canScrollNext())
    }
    const onSelectPrev = () => {
      setSelectedIndex(emblaPrev.selectedScrollSnap() + 1)
      // setPrevBtnEnabled(embla.canScrollPrev())
      // setNextBtnEnabled(embla.canScrollNext())
    }
    if (embla) {
      // setScrollSnaps(embla.scrollSnapList())
      embla.on(`select`, onSelect)
      onSelect()
    }
    if (emblaPrev) {
      // setScrollSnaps(embla.scrollSnapList())
      emblaPrev.on(`select`, onSelectPrev)
      onSelectPrev()
    }
    return () => embla && embla.destroy()
  }, [embla])

  // const [embla, setEmbla] = useState(null)

  if (!models) {
    return null
  }

  useEffect(() => {
    if (selectedIndex) {
      scrollTo(selectedIndex)
      // if (selec÷tedIndex === 0) {
      emblaPrev.scrollTo(selectedIndex - 1)
      // } else {
      // emblaPrev.scrollTo(models.length - 1)
      // }
    }
    if (models) {
      setModel(models[selectedIndex])
    }
  }, [selectedIndex])

  return (
    <div
      className="models-carousel"
      css={css`
        position: relative;
      `}
    >
      <div
        className="models-carousel--category models-carousel--category-left"
        css={css`
          z-index: 1;
        `}
      >
        {models && (
          <EmblaCarousel
            className="embla carousel--category"
            options={{
              align: `start`,
              loop: true,
              dragFree: false,
              startIndex: 0,
              speed: 20,
            }}
            onLoad={embla => {
              setEmblaPrev(embla)
            }}
            select
          >
            {models.map(model => (
              <ModelCarouselCategoryItem
                placeholder={placeholderImage}
                key={`carousel-prev-${model.slug}`}
                model={model}
              />
            ))}
          </EmblaCarousel>
        )}
      </div>
      <div
        className="models-carousel--category models-carousel--category-middle"
        css={css`
          z-index: 3;
          overflow: visible;
        `}
      >
        {model && (
          <StyledInfoBlock className="models-carousel--info-block">
            <div className={`controls`}>
              <Icon className="fas fa-long-arrow-alt-left" onClick={scrollPrev} />
              <Icon className="fas fa-long-arrow-alt-right" onClick={scrollNext} />
            </div>
            <StyledInfoHeader>
              <h4>Our Models</h4>
            </StyledInfoHeader>
            <Title isSize={3}>{model.title}</Title>
            <Content
              css={css`
                p {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-height: 6rem;
                }
              `}
              dangerouslySetInnerHTML={{
                __html: model.excerpt,
              }}
            />
            <Link
              css={css`
                color: ${DARK};
                text-decoration: underline;
              `}
              to={`/models/${model.slug}`}
            >
              View profile
            </Link>
          </StyledInfoBlock>
        )}
      </div>
      <div
        className="models-carousel--category models-carousel--category-right"
        css={css`
          z-index: 1;
        `}
      >
        {models && (
          <EmblaCarousel
            className="embla carousel--category"
            options={{
              align: `start`,
              loop: true,
              dragFree: false,
              startIndex: 1,
            }}
            onLoad={embla => {
              setEmbla(embla)
            }}
          >
            {models.map(model => (
              <ModelCarouselCategoryItem
                placeholder={placeholderImage}
                key={`carousel-${model.slug}`}
                model={model}
              />
            ))}
          </EmblaCarousel>
        )}
      </div>
    </div>
  )
}

export const modelCarouselCategoryQuery = graphql`
  fragment ModelCarouselCategoryImage on File {
    childImageSharp {
      fixed(height: 664) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`
