import css from "@emotion/css"
import { Container, Icon } from "bloomer"
import React from "react"
import { EmblaCarousel } from "./embla.component"
import { ModelsCarouselItem } from "./model-carousel-item.component"

export const CarouselArrow = ({ direction }) => (
  <Icon className={`is-arrow-button fas fa-angle-${direction} fa-lg`} />
)
export const ModelsCarouselCentered = ({ models_gf }) => (
  <div
    className="models-carousel--centered"
    css={css`
      z-index: 1;
      position: relative;
    `}
  >
    {models_gf && (
      <Container>
        <EmblaCarousel
          className="embla carousel--centered"
          options={{ align: `start`, loop: true, dragFree: false }}
        >
          {models_gf.edges.map(({ node: model }) => (
            <ModelsCarouselItem key={`carousel--centered-${model.slug}`} model={model} />
          ))}
        </EmblaCarousel>
      </Container>
    )}
  </div>
)
