import React from "react"

import { FeedbackListItem } from "../atoms/feedback-list-item.component"

export const FeedbackList = props => {
  const { feedback } = props
  return (
    <div className="feedback-list">
      {feedback.map((feedbackItem, i) => (
        <FeedbackListItem key={`feedback-${i}`} {...feedbackItem} />
      ))}
    </div>
  )
}
