import css from "@emotion/css"
import { Button, Content, Icon, Section, Title } from "bloomer"
import { graphql } from "gatsby"
import React from "react"

import { YELLOW } from "../consts/colors.constants"
import { useWindowSize } from "../hooks/useWindowSize.hook"
import { EmblaCarousel } from "./embla.component"
import { ModelsCarouselItem } from "./model-carousel-item.component"

export const CarouselArrow = ({ direction }) => (
  <Icon className={`is-arrow-button fas fa-angle-${direction} fa-lg`} />
)
export const ModelsCarouselHome = ({ models }) => {
  const size = useWindowSize()
  return (
    <Section
      isMarginless
      isPaddingless={false}
      hasTextAlign={`centered`}
      hasTextColor={`light`}
      className="has-text-white is-text has-text-light has-vertical-padding has-background-black"
    >
      <Title
        isFullWidth
        hasTextAlign={`center`}
        css={css`
          color: white;
        `}
        className={`is-text-white`}
      >
        Our Exclusive <span className={`primary`}>High Class Models</span>
      </Title>
      <Content>
        <p>
          All the selected high-class escort models are enjoying this lifestyle and truly like to
          spoil you with their full attention and love. love
        </p>
      </Content>
      {models && (
        <EmblaCarousel className="embla" options={{ align: `start`, loop: true, dragFree: false }}>
          {models.map(model => (
            <ModelsCarouselItem
              key={`carousel-${model.slug}`}
              height={383}
              model={model}
              slidesVisible={Math.floor(size.width / 540)}
            />
          ))}
        </EmblaCarousel>
      )}
      <Button
        css={css`
          background-color: black;
          color: ${YELLOW};
          margin-top: 4rem;
          margin-bottom: 3rem;
        `}
      >
        View All Models
      </Button>
    </Section>
  )
}

export const modelCarouselHomeQuery = graphql`
  fragment ModelCarouselImageSmall on File {
    childImageSharp {
      fixed(height: 383) {
        ...GatsbyImageSharpFixed_withWebp_tracedSVG
      }
    }
  }
  fragment ModelCarouselHomeFields on wordpress__wp_model {
    ...ModelDefaultFields
    acf {
      ...ModelDefaultAcfFields
      gallery_image {
        ...ImageFields
        localFile {
          ...ModelCarouselImageSmall
        }
      }
    }
  }
`
