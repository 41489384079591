import css from "@emotion/css"
import { Link } from "gatsby"
import get from "lodash/get"
import React from "react"

import ImageWrap from "../atoms/image-wrap.atom"
import { parseWPUrl } from "../utils/url.utils"
import { EmblaCarousel } from "./embla.component"

export const GalleryCarousel = ({ height, slides, options, responsiveStyle, slidesVisible }) =>
  slides ? (
    <EmblaCarousel
      options={{
        align: `start`,
        containScroll: true,
        dragFree: true,
        loop: true,
        slidesToScroll: 1,

        ...options,
      }}
      className="embla"
    >
      {slides
        .filter(slide => slide && slide.localFile && slide.localFile.childImageSharp)
        .map(slide => {
          const slideImage = slide.image || slide
          const image_url = get(slideImage, `acf.gallery_image_url.url`)
          return (
            <div
              key={slideImage.id}
              className="embla__slide"
              style={{
                height: height || 383,
                width:
                  responsiveStyle === `fixed` &&
                  get(slideImage, `localFile.childImageSharp.fixed.width`, `100%`),
              }}
            >
              {image_url ? (
                <Link
                  as={get(slideImage, `acf.gallery_image_url.url`) ? `a` : `div`}
                  to={parseWPUrl(get(slideImage, `acf.gallery_image_url.url`))}
                  target="_blank"
                  css={css`
                    flex: 1;
                    position: relative;
                    display: flex;
                  `}
                >
                  <ImageWrap
                    style={{
                      maxHeight: height || 383,
                      height: height || 383,
                    }}
                    objectPosition={slideImage.align}
                    image={slideImage}
                    reponsiveStyle={responsiveStyle || `fixed`}
                  />
                </Link>
              ) : (
                <ImageWrap
                  css={css`
                    flex: 1;
                    position: relative;
                    display: flex;
                  `}
                  style={{
                    maxHeight: height || 383,
                    height: height || 383,
                  }}
                  objectPosition={slideImage.align}
                  image={slideImage}
                  reponsiveStyle={responsiveStyle || `fixed`}
                />
              )}
            </div>
          )
        })}
    </EmblaCarousel>
  ) : null
