import css from "@emotion/css"

import { DARK, LIGHT } from "../consts/colors.constants"

export const themedBox = ({ themeStyle }) => css`
  background-color: ${themeStyle === `dark` ? DARK : LIGHT};
  color: ${themeStyle === `dark` ? LIGHT : DARK};
  h1,
  h2,
  h3 {
    color: ${themeStyle === `dark` ? LIGHT : DARK};
  }
  .title .page-intro--title {
    &:first-line {
      color: ${themeStyle === `dark` ? LIGHT : DARK};
    }
  }
`
